<template>
  <el-dialog title="分配角色" :model-value="modelValue" @close="closed">
    <el-checkbox-group v-model="userRoleTitleList">
      <el-checkbox
        v-for="item in allRolesList"
        :key="item.roleId"
        :label="item.name"
      ></el-checkbox>
    </el-checkbox-group>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed"> 取消 </el-button>
        <el-button type="primary" @click="onConfirm"> 确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import {
  roleList,
  userSCornerListAPI,
  userAllocationRoleAPI
} from '@/api/organization'
import { ElMessage } from 'element-plus'
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  userId: {
    type: String,
    required: true
  }
})
const emits = defineEmits(['update:modelValue'])

watch()

//  确定按钮点击事件
const onConfirm = async () => {
  const roles = userRoleTitleList.value.map((name) => {
    return allRolesList.value.find((role) => role.name === name)
  })
  const roleIds = roles.map((item) => item.roleId).toString()
  await userAllocationRoleAPI({
    id: props.userId,
    roleIds
  })
  ElMessage.success('用户角色添加成功。')
  closed()
}
// 关闭
const closed = () => {
  emits('update:modelValue', false)
}

/** 角色多选 S */
// 所有角色
const allRolesList = ref([])
const getRoleList = async () => {
  allRolesList.value = await roleList()
}
getRoleList()

// 判断 当前用户具有的角色 调用时机

watch(
  () => props.userId,
  (val) => {
    if (val) userSCornerList()
  }
)

// 当前用户具有的角色
const userRoleTitleList = ref([])
const userSCornerList = async () => {
  const res = await userSCornerListAPI({ id: props.userId })
  // 数据回显
  userRoleTitleList.value = res.map((item) => item.role_name)
}

/** 角色多选 E */
</script>

<style lang="scss" scoped></style>
