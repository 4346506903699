<template>
  <div>
    <el-row style="margin-bottom: 20px">
      <el-button type="primary" size="small" @click="onEmployeeAdd">
        添加员工
      </el-button>
    </el-row>

    <el-table
      :data="employeeList"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="userName" label="用户名" />

      <el-table-column label="操作" align="center">
        <template #default="{ row }">
          <el-button
            type="primary"
            size="small"
            @click="onShowRoleClick(row.id)"
          >
            角色
          </el-button>
          <!-- <el-button type="danger" size="small" @click="refundBtn(row)">
            删除
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <roles-dialog v-model="roleDialogVisible" :userId="userId"></roles-dialog>

    <!-- 添加员工 -->
    <el-dialog v-model="dialogVisible" title="添加员工" width="30%">
      <el-form label-width="120px">
        <el-form-item label="姓名">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="用户名">
          <el-input v-model="form.userName" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onBtnCancel">取消</el-button>
          <el-button type="primary" @click="onAddBtn">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import RolesDialog from './components/roles.vue'
import { userallListAPI, addEmployeeAPI } from '@/api/organization'
import { ElMessage } from 'element-plus'

// addEmployeeAPI
import { ref, watch } from 'vue'

const dialogVisible = ref(false)
const form = ref({
  name: '',
  userName: '',
  deptId: '1'
})

// 员工添加
const onEmployeeAdd = () => {
  dialogVisible.value = true
}

// 确认
const onAddBtn = async () => {
  await addEmployeeAPI(form.value)
  ElMessage.success('人员添加成功')
  dialogVisible.value = false
  onUserallList()
  form.value = {
    name: '',
    userName: '',
    deptId: '1'
  }
}

// 取消
const onBtnCancel = () => {
  dialogVisible.value = false
  form.value = {
    name: '',
    userName: '',
    deptId: '1'
  }
}

/** 表格 S */
const employeeList = ref([])
const onUserallList = async () => {
  const data = await userallListAPI()
  employeeList.value = data
  console.log(data)
}
onUserallList()

/**
 * 查看角色的点击事件
 */
const roleDialogVisible = ref(false)
const userId = ref('')
const onShowRoleClick = (id) => {
  roleDialogVisible.value = true
  userId.value = id
}

watch(roleDialogVisible, (val) => {
  if (!val) userId.value = ''
})
/** 表格 E */
</script>

<style lang="scss" scoped></style>
